import styled from "@emotion/styled"

const PageHeaders = styled.h1`
  font-family: Montserrat;
  font-weight: 900;
  text-transform: uppercase;
  color: #312e2e;
  font-size: 2rem;
  border-bottom: 8px solid #f07525;
  @media (max-width: 640px) {
    font-size: 1.5rem;
  }
`

export default PageHeaders
